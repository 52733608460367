import { Injectable } from '@angular/core';
import { components } from '../../shared/api/models';
import { arrayContainsArrayPartial } from '../../shared/utils/formUtils';

export type Resource = components['schemas']['ResourceMaster']['resourceName'];
// components['schemas']['RolePermissionRecord']['resource'];
export type Action = components['schemas']['Action']['actionName'];
//  = components['schemas']['RoleActionRecord']['name'];
// export type Effect = components['schemas']['RoleActionRecord']['effect'];
export type Effect = '';
@Injectable({ providedIn: 'root' })
export class RoleService {
  // #secureLocalstorageService = inject(SecureLocalStorageService);

  getRole() {
    return JSON.parse(
      typeof JSON.parse(localStorage.getItem('USER_PERMISSION') || '{}') ===
        'string'
        ? JSON.parse(localStorage.getItem('USER_PERMISSION') || '{}')
        : localStorage.getItem('USER_PERMISSION') || '{}'
    );
  }

  getPermissions() {
    return this.getRole()['USER_PERMISSION'] || [];
  }

  hasPermission(onResource: Resource, withActions: Action[]) {
    // const role = <components['schemas']['Role']>this.getRole();
    // const role:any = '';
    // const currentPermissions = role?.permissions;
    // console.log('resource-->> ', onResource);
    // console.log('withActions-->> ', withActions);
    const currentPermissions = this.getRole();
    // console.log('currentpermissions: ', currentPermissions?.parsedPermission);
    if (
      !currentPermissions?.parsedPermission ||
      currentPermissions?.parsedPermission.length === 0
    )
      return false;
    const currentResource = currentPermissions?.parsedPermission.find(
      (x: any) => x.resourceName === onResource
    );
    // console.log('current resource: ', currentResource);

    if (!currentResource) return false;

    const allowedActions =
      currentResource.actions
        ?.filter((x: any) => withActions.includes(x.actionName))
        .map((x: any) => x.actionName) || [];
    // console.log('allowedActions-->> ', allowedActions);
    if (!arrayContainsArrayPartial(allowedActions, withActions)) return false;
    return true;
  }

  hasRole(roleInput: String) {
    const role = <components['schemas']['Role']>this.getRole();
    if (
      role.roleName &&
      role.roleName.toLowerCase() === roleInput.toLocaleLowerCase()
    ) {
      return true;
    }
    return false;
  }
  hasRoles(roles: string[]) {
    return roles.filter((x) => this.hasRole(x)).length > 0;
  }
}
