import {
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../../auth/store/auth.service';
import { MessageService } from 'primeng/api';

export const TokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
): Observable<HttpEvent<any>> => {
  const interceptDomains = 'http://localhost:8080';
  const router = inject(Router);
  const messageService = inject(MessageService);
  const authService = inject(AuthService);
  const url = req.url;
  // console.log('url :: ',url);
  const route = router.url;

  if (
    url.includes(interceptDomains) ||
    url.includes('http://20.244.34.6/api') ||
    url.includes('https://duowits.com/api')||
    url.includes('https://bdforce.myjwil.in/api')
  ) {
    // if (authService.getToken()) {
    //   const tokenizedRequest = appendTokenToRequest(req, authService.getToken());
    //   return next(tokenizedRequest);
    // } else {
      return next(req).pipe(
        catchError((err) => {
          console.log('Interceptor Err:: ', err);
// &&
// !route.includes('login')
          if (
            (err.status === 401 || err.status === '401') 
          ) {
            for (let index = 0; index < 1; index++) {
              messageService.add({
                severity: 'warn',
                detail: err?.error.message,
                key: 'root-msgs',
              });
            }
            localStorage.clear();

            setTimeout(() => {
              authService.logout();
            }, 2000);
          }
          return throwError(() => err);
        })
      );
    // }
  }
  return next(req);
};

const appendTokenToRequest = (req: HttpRequest<any>, token:string) => {
  // const token = localStorage.getItem('jwt');
  // console.log('token :: ',token);
  const tokenizedRequest = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`),
  });
  // console.log('tokenizedRequest :: ',tokenizedRequest);
  return tokenizedRequest;
};
